<template>
    <section class="section">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
            <v-container class="mt-10" style="max-height: 70vh">
                <div id="user-list" class="mt-8">
                    <!-- actions -->
                    <div class="d-flex">
                        <!-- search -->
                        <v-text-field
                            expanded
                            v-model="search"
                            @change="searchEntitiesCategories()"
                            :placeholder="$t('Search')"
                            outlined
                            hide-details
                            dense
                            :prepend-inner-icon="icons.mdiMagnify"
                            class="user-search me-3 mb-4">
                        </v-text-field>
                    </div>
                    <v-tabs>
                        <v-tab>{{ $t('EntityCategoryList') }}</v-tab>
                        <v-tab-item>
                            <v-card class="mt-8">
                                <template>
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        v-model="selected"
                                        :headers="tableColumns"
                                        :items="entityCategoryListTable"
                                        :server-items-length="totalEntityCategoryListTable"
                                        :loading="isLoading"
                                        :options.sync="options"
                                        @update:page="paginationHandle"
                                        height="310"
                                        fixed-header>
                                        <!-- name -->
                                        <template #[`item.name`]="{ item }">
                                            <div class="d-flex align-center">
                                                <v-avatar v-if="resolveEntityCategoryPicture(item.icon)" size="20">
                                                    <v-img :src="resolveEntityCategoryPicture(item.icon)"></v-img>
                                                </v-avatar>
                                                <v-avatar
                                                    v-else
                                                    :color="item.avatar ? '' : 'primary'"
                                                    :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                                                    size="32">
                                                    <span class="caption font-weight-medium">{{
                                                        avatarText(item.name)
                                                    }}</span>
                                                </v-avatar>
                                                <div class="extend-column d-flex flex-column ms-3">
                                                    <router-link
                                                        style="max-width: 12rem"
                                                        :to="{ name: 'EntityCategoryProfile', params: { id: item.id } }"
                                                        class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                                        {{ item.name }}
                                                    </router-link>
                                                </div>
                                            </div>
                                        </template>
                                        <!-- entities -->
                                        <template v-slot:item.accessibleLocations="{ item }">
                                            <span v-if="item.accessibleLocations.length > 0">{{
                                                item.accessibleLocations[0].name
                                            }}</span>
                                            <v-tooltip bottom v-if="item.accessibleLocations.length > 1">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span
                                                        class="info--text"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        style="cursor: default"
                                                        >{{ limitEntityText(item.accessibleLocations) }}
                                                        {{
                                                            $tc(
                                                                'EntityTooltip',
                                                                item.accessibleLocations.slice(1).length,
                                                            )
                                                        }}</span
                                                    >
                                                </template>
                                                <span>{{
                                                    item.accessibleLocations
                                                        .slice(1)
                                                        .map((entry) => entry.name)
                                                        .join(', ')
                                                }}</span>
                                            </v-tooltip>
                                        </template>

                                        <!-- actions -->
                                        <template #[`item.actions`]="{ item }">
                                            <v-btn
                                                @click="
                                                    $router.push({
                                                        name: 'EntityCategoryProfile',
                                                        params: { id: item.id },
                                                    })
                                                "
                                                icon>
                                                <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-card>
                        </v-tab-item>
                    </v-tabs>
                </div>
                <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR CATEGORIAS -->
                <v-snackbar :timeout="2000" top v-model="isSuccess" color="white">
                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                    <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                    <p class="text-center black--text">{{ $t('Alerts.DeleteEntityCategorySuccess') }}</p>
                </v-snackbar>
                <v-snackbar :timeout="2000" top v-model="isError" color="white">
                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
                    <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
                    <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
                </v-snackbar>
            </v-container>
        </v-sheet>
        <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-container class="d-flex">
                <v-toolbar-title>
                    <p class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1">
                        {{ $t('EntityCategoryList') }}
                    </p>
                    <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                        {{ $t('EntityCategoryList') }}
                    </p>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn
                    @click="$router.push({ name: 'EntityCategoryProfile', params: { id: 'add' } })"
                    color="primary"
                    class="mx-3">
                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                        {{ icons.mdiPlus }}
                    </v-icon>
                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                        {{ icons.mdiPlus }}
                    </v-icon>
                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('CreateEntityCategory') }}</span>
                </v-btn>
                <v-btn
                    @click="$router.push({ name: 'importEntityCategories' })"
                    color="primary"
                    class="mx-3"
                    style="position: relative; overflow: hidden">
                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                        {{ icons.mdiUpload }}
                    </v-icon>
                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                        {{ icons.mdiUpload }}
                    </v-icon>
                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('ImportEntityCategories') }}</span>
                </v-btn>
                <v-dialog v-if="selected.length > 0" transition="dialog-top-transition" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="selected.length === 1" class="body-2">
                                    {{ $t('Alerts.SureDeleteEntityCategory') }}
                                    <strong class="black--text">{{
                                        selected.map((entry) => entry.name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteEntitiesCategories') }}
                                    <strong class="black--text">{{
                                        selected.map((entry) => entry.name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn @click="deleteSelectedEntities" color="error" class="px-5">{{
                                    $t('Buttons.Delete')
                                }}</v-btn>
                                <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-container>
        </v-app-bar>
    </section>
</template>

<script>
    import {
        mdiAccountOutline,
        mdiChevronRight,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiFilterVariant,
        mdiMagnify,
        mdiPlus,
        mdiUpload,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
    } from '@mdi/js';
    import { ref } from '@vue/composition-api';

    // sidebar
    import { avatarText } from '@core/utils/filter';
    import useEntityCategoryList from './useEntityCategoryList';
    import useEntityList from '../../entities/entity-list/useEntityList';

    export default {
        setup() {
            const {
                entityCategoryListTable,
                tableColumns,
                searchQuery,
                roleFilter,
                planFilter,
                statusFilter,
                totalEntityCategoryListTable,
                loading,
                options,
                entityCategoryTotalLocal,
                selectedRows,

                fetchEntityCategoryList,
                deleteEntityCategory,
                deleteManyEntitiesCategories,
                resolveEntityCategoryPicture,
                resolveEntityCategoryRoleVariant,
                resolveEntityCategoryRoleIcon,
                resolveEntitiesCategoriesStatusVariant,
                resolveEntityCategoryTotalIcon,
            } = useEntityCategoryList();

            const { limitEntityText } = useEntityList();

            const isFiltersActive = ref(false);

            const roleOptions = [
                { title: 'Administrador', value: 'admin' },
                { title: 'CEO', value: 'ceo' },
                { title: 'Responsável', value: 'responsable' },
            ];

            const planOptions = [
                { title: 'Basic', value: 'basic' },
                { title: 'Company', value: 'company' },
                { title: 'Enterprise', value: 'enterprise' },
                { title: 'Standard', value: 'standard' },
            ];

            const statusOptions = [
                { title: 'Pending', value: 'pending' },
                { title: 'Active', value: 'active' },
                { title: 'Inactive', value: 'inactive' },
            ];

            return {
                entityCategoryListTable,
                tableColumns,
                searchQuery,
                roleFilter,
                planFilter,
                statusFilter,
                totalEntityCategoryListTable,
                roleOptions,
                planOptions,
                statusOptions,
                loading,
                options,
                entityCategoryTotalLocal,
                isFiltersActive,
                selectedRows,
                avatarText,
                resolveEntityCategoryPicture,
                resolveEntityCategoryRoleVariant,
                resolveEntityCategoryRoleIcon,
                resolveEntitiesCategoriesStatusVariant,
                resolveEntityCategoryTotalIcon,
                fetchEntityCategoryList,
                deleteEntityCategory,
                deleteManyEntitiesCategories,
                limitEntityText,

                // icons
                icons: {
                    mdiSquareEditOutline,
                    mdiFileDocumentOutline,
                    mdiDotsVertical,
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiUpload,
                    mdiExportVariant,
                    mdiAccountOutline,
                    mdiMagnify,
                    mdiChevronRight,
                    mdiFilterVariant,
                    mdiTrashCanOutline,
                },
            };
        },
        created() {
            this.isLoading = true;
            this.$store.commit('app-entity-category/resetEntityCategoryQuery');
            this.query = this.$store.getters['app-entity-category/getEntityCategoryQuery'];
            this.fetchEntityCategoryList();

            setTimeout(() => {
                this.isLoading = false;
            }, 2000);
        },
        data() {
            return {
                search: null,
                selected: [],
                isFiltersActive: false,
                isLoading: false,
                isSuccess: false,
                isError: false,
                query: {},
                options: {
                    page: 1,
                },
            };
        },
        watch: {
            options: {
                handler() {
                    this.query.pagination.page = this.options.page;
                    this.query.pagination.pageSize = this.options.itemsPerPage;
                    if (this.options.sortBy) {
                        this.query.sort = [`${this.options.sortBy}:${this.options.sortDesc[0] ? 'desc' : 'asc'}`];
                    }
                    if (this.options.sortBy.length === 0) {
                        this.query.sort = ['id:asc'];
                    }
                    this.$store.commit('app-entity-category/setEntityCategoryQuery', this.query);
                    this.fetchEntityCategoryList();
                },
                deep: true,
            },
        },
        methods: {
            getEntityCategoryList() {
                this.isLoading = true;
                this.fetchEntityCategoryList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 4000);
            },
            searchEntitiesCategories() {
                this.isLoading = true;
                this.$store.commit('app-entity-category/setEntityCategorySearch', this.search);
                this.query = this.$store.getters['app-entity-category/getEntityCategoryQuery'];
                this.options.page = 1;
                this.query.pagination.page = 1;
                this.query.pagination.pageSize = this.options.itemsPerPage;
                this.fetchEntityCategoryList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 2000);
            },
            deleteSelectedEntities() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-entity-category/deleteManyEntitiesCategories', {
                        where: {
                            id: { $in: this.selected.map((item) => item.id) },
                        },
                    })
                    .then((response) => {
                        this.isSuccess = true;
                        this.$store.commit('app-entity-category/setIsDeleteEntityCategory', true);
                        this.fetchEntityCategoryList();
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 2000);
                    })
                    .catch((error) => {
                        this.isError = true;

                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                    });
                this.selected = [];
            },

            paginationHandle(e) {
                if (this.options.page > this.query.pagination.page) {
                    this.query.pagination.page = this.options.page;
                    this.query.pagination.pageSize = this.options.itemsPerPage;
                    this.$store.commit('app-entity-category/setEntityCategoryQuery', this.query);
                    this.fetchEntityCategoryList();
                }
            },
        },
    };
</script>

<style lang="scss">
    @import '@core/preset/preset/apps/user.scss';
    @import '@/styles/variables.scss';
    progress-bar {
        background-color: #58585836;
    }
    .trash-icon {
        width: 60rem;
        height: 60rem;
    }
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }
</style>
./useEntityCategoryList
