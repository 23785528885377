import store from '@/store';
import { mdiAccountOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import en from '@/plugins/i18n/locales/en';

export default function useEntitiesCategoriesList() {
    const entityCategoryListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Entities'), value: 'accessibleLocations', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalEntityCategoryListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const entityCategoryTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');

    // fetch data
    const fetchEntityCategoryList = () => {
        let verifyQuery = store.getters['app-entity-category/getEntityCategoryQuery'];
        let isDeleteEntityCategory = store.getters['app-entity-category/getIsDeleteEntityCategory'];
        store
            .dispatch('app-entity-category/fetchEntityCategoryList', {})
            .then((response) => {
                if (isDeleteEntityCategory) {
                    entityCategoryListTable.value = [];
                    entityCategoryListTable.value.push(...response.data.data);
                    store.commit('app-entity-category/setIsDeleteEntityCategory', false);
                } else if (oldSearch.value == verifyQuery.filters.$or[0].name.$containsi) {
                    entityCategoryListTable.value = [];
                    entityCategoryListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    entityCategoryListTable.value = [];
                    entityCategoryListTable.value.push(...response.data.data);
                }
                totalEntityCategoryListTable.value = response.data.meta.pagination.total;

                // entityCategoryTotalLocal.value = entityTotal;
                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchEntityCategoryList();
    });

    const resolveEntityCategoryPicture = (pictures) => {
        if (_.get(pictures, 'formats.thumbnail.url')) return _.get(pictures, 'formats.thumbnail.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveEntityCategoryPictureMax = (pictures) => {
        console.log(pictures);
        if (_.get(pictures, 'formats.large.url')) return _.get(pictures, 'formats.large.url');

        if (_.get(pictures, 'url')) return _.get(pictures, 'url');
        return null;
    };
    const resolveEntityCategoryRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveEntityCategoryRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveEntitiesCategoriesStatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const limitEntityCategoryText = (entities) => {
        if (entities.length > 1) {
            return ' + ' + (entities.length - 1);
        }
    };

    return {
        entityCategoryListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalEntityCategoryListTable,
        loading,
        options,
        entityCategoryTotalLocal,
        selectedRows,
        fetchEntityCategoryList,
        resolveEntityCategoryPicture,
        resolveEntityCategoryPictureMax,
        resolveEntityCategoryRoleVariant,
        resolveEntityCategoryRoleIcon,
        resolveEntitiesCategoriesStatusVariant,
        limitEntityCategoryText,
    };
}
